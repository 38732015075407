.abtCont {
    background-color: white;
    /* background-image: linear-gradient(#ffffff9f, #ffffff), url('../images/Hexagon.svg'); */
    background-size: 200px;
    background-repeat: repeat;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.aboutContainer {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1400px;
    display: flex;
    flex-direction: row;
    gap: 2%;
}

/* Media Queries */
@media screen and (max-width: 1100px) {
    .aboutContainer {
        flex-direction: column;
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 700px) {
    .aboutContainer {
        padding-top: 30px;
    }
}

@media screen and (max-width: 440px) {
    .aboutContainer {
        padding-left: 0;
        padding-right: 0;
    }
}

.firstAboutSection {

}

.navigation {
    
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 5px;
    text-transform: uppercase;
    font-weight: 500;
    align-items: center;
    margin: 0 20px;  
}
.navigation > svg {
    color: #023047;
    margin-right: 5px;
}
.navigation > span {
    cursor: pointer;
}
.navigation > span:last-child {
    color: rgb(202, 131, 0);
    cursor: default;
}
.aboutSideMenu {
    /* background-color: #023047; */
    max-width: 310px;
    min-width: 230px;
    @media screen and (width < 1100px) {
        display: none;
    }
    
}
.aboutSideMenu > div {
    padding: 13px 20px;
    cursor: pointer;
    

}
.aboutItem {
    color: grey;
    border-bottom: 1px solid #b3b3b3;

}
.aboutItem1 {
    background-color: #e69500;
    color: white;
    font-weight: 500;
    border-radius: 0px;

}
.sectionContainer {
    /* Styles for the sectionContainer itself can be added here */
}

.sectionContainer .imgContainer {
    flex: 1.2;
    border-radius: 10px;
    overflow: hidden;
    aspect-ratio: 3.8 / 2;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    width: 100%;
    height: 100%;
}

.sectionContainer video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
}
.sectionContainer .video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    aspect-ratio: 4/2;
}

@keyframes loadingShimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

/* Media Queries */
@media screen and (max-width: 1000px) {
    .sectionContainer .imgContainer {
        margin: 0 20px;
        border-radius: 10px;
    }
}

@media screen and (max-width: 590px) {
    .sectionContainer .imgContainer {
        margin: 0;
        border-radius: 0;
    }
}

.sectionContainer > .container {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-bottom: 30px;
    padding: 3px;
}

/* Media Queries */
@media screen and (max-width: 1000px) {
    .sectionContainer > .container {
        flex-direction: column;
    }
}

@media screen and (max-width: 700px) {
    .sectionContainer > .container {
        padding-left: 0;
        padding-right: 0;
    }
}


.sectionContainer > .container > .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self: flex-start;
}

/* Media Queries */
@media screen and (min-width: 1490px) {
    .sectionContainer > .container > .info {
        justify-content: space-between;
        height: 270px;
    }
}

@media screen and (max-width: 1100px) {
    .sectionContainer > .container > .info {
        padding: 0 20px;
    }
}


.preTitle {
    color: grey;
    font-weight: 500;
    font-size: 17px;
    
}
.sectionContainer > .container > .info > .title {
    position: relative;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
    color: #023047;
}
.sectionContainer > .container > .info > .description {
    color: #3b3b3b;

    
}
.sectionContainer > .container > .info > .title::before {
    position: absolute;
    content: "";
    border-top: solid 8px #ffa500;
    border-radius: 100px;
    max-width: 300px;
    width: 100%;
    min-width: 100px;
    top: -10px;
    
}
.container1 {
    display: flex;
    flex-direction: column;
}

/* Media Queries */
@media screen and (max-width: 1100px) {
    .container1 {
        padding: 0 20px;
    }
}

.container1 .grid1 {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
}

.container1 .grid1 .gridItem {
    padding: 15px 20px;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.24);
    background-color: white;
}

.container1 .grid1 .gridItem .title {
    font-size: 16px;
    font-weight: 500;
    margin: 15px 0;
}

.container1 .grid1 .gridItem .description {
    font-size: 14px;
}

.container1 .grid1 img {
    width: 35px;
    height: 35px;
}

/* Media Queries for .grid1 */
@media screen and (max-width: 750px) {
    .container1 .grid1 {
        grid-template-columns: repeat(1, 1fr);
    }
}

.container1 .grid2 {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.24);
}

/* Media Queries for .grid2 */
@media screen and (max-width: 1315px) {
    .container1 .grid2 {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 750px) {
    .container1 .grid2 {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 520px) {
    .container1 .grid2 {
        grid-template-columns: repeat(1, 1fr);
    }
}

.container1 .grid2 > .value1 {
    box-shadow: none;
    gap: 20px;
    justify-content: flex-start;
}

.aboutOptions {
    position: relative; 
    width: 230px;
    margin: 0 20px;
    
    
}
.aboutOptionsButton {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    color: #e69500;
    border-radius: 5px;
    width: 100%;
    justify-content: center;
    border: 2px solid #e69500;
    font-size: 14px;
}

.aboutOptionsList {
    background-color: white;
    position: absolute;
    width: 100%;
    margin-top: 10px;
    border: 0.6px solid #cccccc;
    border-radius: 10px;
    width: 230px;
    
}
.optionItem {
    color: black;
    padding: 10px 15px;
    margin: 10px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}
.optionItem1 {
    color: white;
    padding: 10px 15px;
    margin: 10px;
    border-radius: 5px;
    background-color: #e69500;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;

}
.aboutFirst {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
}

/* Media Queries */
@media screen and (max-width: 700px) {
    .aboutFirst {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
        margin-bottom: 5px;
    }
}


.aboutObjectives {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}
.aboutObjective {
    display: flex;
    flex-direction: row;
    background: white;
    overflow: hidden;
    border-top-left-radius: 20px;
}

.aboutObjective .iconContainer {
    background-color: orange;
    padding: 15px;
    max-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutObjective .iconContainer svg {
    width: 25px;
    height: 25px;
    color: white;
}

/* Media Queries */
@media screen and (max-width: 500px) {
    .aboutObjective .iconContainer {
        padding: 10px;
    }

    .aboutObjective .iconContainer svg {
        width: 20px;
        height: 20px;
    }
}

.aboutObjective .objectiveText {
    flex: 20;
    padding: 10px 15px;
    background-color: white;
    margin-bottom: 20px;
    border-bottom-right-radius: 20px;
    border: 1px solid #cecece9c;
    min-height: 70px;
}
