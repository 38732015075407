html, body {
    margin: 0;
    padding: 0;
    background-color: white;
    
    /* color: #02304765; */
    
}


#root {
  background-color: white;
  height: 100%;
  max-width: 100vw;
}

.carousel .slide {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel .slide.selected {
  opacity: 1;
}
.alertButtonPrev {
  background-color: #ffffffbd;
  position: absolute;
  top: 45%;
  left: 0;
  z-index: 100;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
  cursor: pointer;
}
.alertButtonNext {
  background-color: #ffffffbd;
  position: absolute;
  top: 45%;
  right: 0;
  z-index: 100;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 500px;
  cursor: pointer;
}

.alertContainer {
  aspect-ratio: 4/4;
  width: 100%;
  margin-top: 20px;
  border-radius: 15px;
  overflow: hidden;
  border: 5px solid rgb(240, 240, 240);
  
  background-color: #5c5c5c;
            background-image: linear-gradient(90deg, rgba(91,91,91,1.00) 0%,rgba(112,112,112,1.00) 40%,rgba(91,91,91,1.00) 80%,rgba(91,91,91,1.00) 97%);
            animation-duration: 1s;
              animation-fill-mode: forwards;
              animation-iteration-count: infinite;
              animation-name: loadingShimmer;
              animation-timing-function: linear;
              background-color: #efefef;
              background-image: linear-gradient(90deg, rgba(239,239,239,1.00) 0%,rgba(221,221,221,1.00) 40%,rgba(239,239,239,1.00) 80%,rgba(239,239,239,1.00) 97%);
              background-repeat: no-repeat;
              background-size: 50%;
            @keyframes loadingShimmer {
                0% {
                  background-position: -100% 0;
                }
              
                100% {
                  background-position: 200% 0;
                }
              }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  
}

.alertInfo {
  .caption {
    color: grey;
    background-color: #eeeeee;
    padding: 15px;
    border-radius: 15px;
    color: grey;
    font-size: 14px;
    text-align: left;
    width: 100%;
    
  }
  
  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    gap: 10px;
    .profileTitle {
      font-size: 11px;
      max-width: 250px;
      text-align: left;
      text-transform: uppercase;
      font-weight: 700;
      color: grey;
    }
    .profilepic {
      width: 45px;
      
      height: 45px;
      border-radius: 500px;
      background-color: #5c5c5c;
            background-image: linear-gradient(90deg, rgba(91,91,91,1.00) 0%,rgba(112,112,112,1.00) 40%,rgba(91,91,91,1.00) 80%,rgba(91,91,91,1.00) 97%);
            animation-duration: 1s;
              animation-fill-mode: forwards;
              animation-iteration-count: infinite;
              animation-name: loadingShimmer;
              animation-timing-function: linear;
              background-color: #efefef;
              background-image: linear-gradient(90deg, rgba(239,239,239,1.00) 0%,rgba(221,221,221,1.00) 40%,rgba(239,239,239,1.00) 80%,rgba(239,239,239,1.00) 97%);
              background-repeat: no-repeat;
              background-size: 50%;
            @keyframes loadingShimmer {
                0% {
                  background-position: -100% 0;
                }
              
                100% {
                  background-position: 200% 0;
                }
              }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        
      }
    }
  }
  
}


.avisoContainer {
  aspect-ratio: 4/4;
  width: 100%;
  margin: 20px 0;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  background-color: #5c5c5c;
            background-image: linear-gradient(90deg, rgba(91,91,91,1.00) 0%,rgba(112,112,112,1.00) 40%,rgba(91,91,91,1.00) 80%,rgba(91,91,91,1.00) 97%);
            animation-duration: 1s;
              animation-fill-mode: forwards;
              animation-iteration-count: infinite;
              animation-name: loadingShimmer;
              animation-timing-function: linear;
              background-color: #efefef;
              background-image: linear-gradient(90deg, rgba(239,239,239,1.00) 0%,rgba(221,221,221,1.00) 40%,rgba(239,239,239,1.00) 80%,rgba(239,239,239,1.00) 97%);
              background-repeat: no-repeat;
              background-size: 50%;
            @keyframes loadingShimmer {
                0% {
                  background-position: -100% 0;
                }
              
                100% {
                  background-position: 200% 0;
                }
              }
}

/* background-color: #5c5c5c;
            background-image: linear-gradient(90deg, rgba(91,91,91,1.00) 0%,rgba(112,112,112,1.00) 40%,rgba(91,91,91,1.00) 80%,rgba(91,91,91,1.00) 97%);
            animation-duration: 1s;
              animation-fill-mode: forwards;
              animation-iteration-count: infinite;
              animation-name: loadingShimmer;
              animation-timing-function: linear;
              background-color: #efefef;
              background-image: linear-gradient(90deg, rgba(239,239,239,1.00) 0%,rgba(221,221,221,1.00) 40%,rgba(239,239,239,1.00) 80%,rgba(239,239,239,1.00) 97%);
              background-repeat: no-repeat;
              background-size: 50%;
            @keyframes loadingShimmer {
                0% {
                  background-position: -100% 0;
                }
              
                100% {
                  background-position: 200% 0;
                }
              } */