.healthContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

@media screen and (max-width: 1100px) {
    .healthContainer {
        padding: 0 10px;
    }
}

@media screen and (max-width: 1080px) {
    .healthContainer {
        flex-direction: column;
    }
}
.healthContainer .firstContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.healthContainer .firstContainer .section {
    padding: 10px;
    background-color: white;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    margin-bottom: 20px;
}

.healthContainer .firstContainer .section .title {
    font-weight: 500;
    font-size: 15px;
    margin: 0;
}

.healthContainer .firstContainer .player {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.healthContainer .firstContainer .playerInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.healthContainer .firstContainer .playerInfo .title {
    color: #023047;
    font-weight: 500;
    font-size: 15px;
}

.healthContainer .firstContainer .description {
    background-color: #eeeeee;
    padding: 15px;
    border-radius: 10px;
    color: grey;
    font-size: 14.5px;
}

.healthContainer .firstContainer .videoContainer {
    border-radius: 5px;
    aspect-ratio: 4/2;
    height: 100%;
    width: 100%;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
}

.healthContainer .firstContainer .videoContainer img {
    aspect-ratio: 4/3;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.healthContainer .firstContainer .imgContainer {
    padding: 15px;
    background-color: #e7eff3;
    border-radius: 100px;
    margin: 0;
    display: inline-block;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #023047;
    font-size: 16px;
    font-weight: 500;
}

.healthContainer .firstContainer .outputs {
    margin-top: 20px;
    font-size: 14px;
}

.healthContainer .firstContainer .outputs ol {
    text-decoration: dotted;
    padding: 0 20px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: grey;
    list-style: circle;
}

.healthContainer .firstContainer .outputs ul {
    text-decoration: dotted;
    padding: 0 20px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: grey;
    list-style: circle;
}

.healthContainer .firstContainer .outputs li:first-child {
    margin-top: 10px;
}

.healthContainer .firstContainer .recommendations {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    gap: 15px;
}

.healthContainer .firstContainer .recommendations .recom {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.healthContainer .firstContainer .recommendations .recom .imageContainer {
    flex: 1;
    aspect-ratio: 4/2;
    border-radius: 10px;
    overflow: hidden;
    max-width: 150px;
    background-color: #e3e3e3;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
}

.healthContainer .firstContainer .recommendations .recom .imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.healthContainer .firstContainer .recommendations .recom .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
}

.healthContainer .firstContainer .recommendations .recom .info .title {
    font-size: 14px;
    color: grey;
    font-weight: 400;
}

/* Media Queries */
@media screen and (max-width: 440px) {
    .healthContainer .firstContainer .description {
        font-size: 13px;
    }
}

@media screen and (max-width: 1080px) {
    .healthContainer .firstContainer .recommendations {
        flex-direction: row;
        justify-content: space-between;
    }

    .healthContainer .firstContainer .recommendations .recom {
        flex: 1;
    }
}

@media screen and (max-width: 700px) {
    .healthContainer .firstContainer .recommendations {
        flex-direction: column;
    }
}

@keyframes loadingShimmer {
    0% {
        background-position: -100% 0;
    }

    100% {
        background-position: 200% 0;
    }
}
.healthContainer .secondContainer {
    flex: 2.5;
    margin: 0;
    padding: 0;
    position: relative;
    padding-bottom: 20px;
}

@media screen and (max-width: 1100px) {
    .healthContainer .secondContainer {
        height: 100%;
    }
}

.healthContainer .secondContainer .courses {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-right: 5px;
    justify-content: stretch;
}

@media screen and (max-width: 1080px) {
    .healthContainer .secondContainer .courses {
        justify-content: stretch;
        margin: 0;
        gap: 15px;
    }
}

@media screen and (max-width: 630px) {
    .healthContainer .secondContainer .courses {
        grid-template-columns: repeat(1, 1fr);
    }
}

.healthContainer .secondContainer .course {
    box-shadow: 0px 0px 100px rgba(151, 151, 151, 0.24);
    margin: 10px;
    position: relative;
    background-color: #e7e7e7;
    border-radius: 5px;
    overflow: hidden;
}

@media screen and (max-width: 1080px) {
    .healthContainer .secondContainer .course {
        margin: 0;
    }
}

.healthContainer .secondContainer .course:hover img {
    filter: brightness(0.5);
    transform: scale(1.2);
}

.healthContainer .secondContainer .course:hover .info > .title::before {
    width: 100%;
}

.healthContainer .secondContainer .course .imageContainer {
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
    aspect-ratio: 4/2;
    overflow: hidden;
}

.healthContainer .secondContainer .course .imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s ease-in-out;
}

.healthContainer .secondContainer .course .info {
    background-color: white;
    padding: 10px;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.healthContainer .secondContainer .course .info .title {
    font-weight: 600;
    margin-top: 10px;
}

.healthContainer .secondContainer .course .info .title::before {
    position: absolute;
    content: "";
    border-top: solid 6px orange;
    border-radius: 100px;
    width: 250px;
    min-width: 100px;
    top: -10px;
    transition: 0.3s ease-in-out;
}

.healthContainer .secondContainer .course .info .basicInfo {
    color: grey;
    font-size: 14px;
}

.healthContainer .secondContainer .course .learnMore {
    border: 2px orange solid;
    width: 100px;
    text-align: center;
    border-radius: 5px;
    color: orange;
    font-size: 13px;
    padding: 3px 5px;
    margin-top: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    align-self: flex-start;
    position: relative;
    overflow: hidden !important;
    background: transparent !important;
    z-index: 10;
}

.healthContainer .secondContainer .course .learnMore:hover {
    background: orange;
    color: white;
}

@media screen and (max-width: 440px) {
    .healthContainer .secondContainer .course .learnMore {
        width: 80px;
        font-size: 12px;
    }
}

.healthContainer .secondContainer .course .learnMore::before {
    content: '';
    width: 0%;
    height: 100%;
    display: block;
    background: #e69500;
    position: absolute;
    transform: skewX(-20deg);   
    left: -10%;
    top: 0;
    z-index: -12;
    transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
    box-shadow: 2px 0px 14px rgba(0, 0, 0, .6);
}

.healthContainer .secondContainer .course .learnMore::after {
    content: '';
    width: 0%;
    height: 100%;
    display: block;
    background: #001d6b;
    position: absolute;
    transform: skewX(-20deg);   
    left: -10%;
    top: 0;
    z-index: -15;
    transition: all .4s cubic-bezier(.2, .95, .57, .99);
    box-shadow: 2px 0px 14px rgba(0, 0, 0, .6);
}

.healthContainer .secondContainer .course .learnMore:hover::before {
    opacity: 1;
    width: 116%;
}

.healthContainer .secondContainer .course .learnMore:hover::after {
    opacity: 1;
    width: 120%;
}

@keyframes loadingShimmer {
    0% {
        background-position: -100% 0;
    }

    100% {
        background-position: 200% 0;
    }
}
.coursesScrollArea {
    background-color: white;
    border: 1px solid #dfdfdf;
    height: 100%;
    max-height: 90vh;
}

@media screen and (max-width: 1080px) {
    .coursesScrollArea {
        height: auto;
        max-height: 100%;
        border: none;
    }
}
.aboutCourse {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    border: 1px solid #dfdfdf;
    padding: 15px;
    position: relative;
    border-radius: 5px;
}

.aboutCourse .imageContainer {
    aspect-ratio: 4/2;
    overflow: hidden;
    border-radius: 5px;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
}

.aboutCourse .imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.aboutCourse .first {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 15%;
    flex-wrap: wrap;
    margin-top: -15px;
}

.aboutCourse .first .apply {
    border: 2px orange solid;
    width: 90px;
    text-align: center;
    border-radius: 5px;
    color: orange;
    font-size: 13px;
    padding: 5px;
    box-sizing: border-box;
    margin-top: 15px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    align-self: flex-start;
    position: relative;
    overflow: hidden !important;
    background: transparent !important;
    z-index: 10;
}

.aboutCourse .first .apply:hover {
    color: white;
}

.aboutCourse .first .apply::before {
    content: '';
    width: 0%;
    height: 100%;
    display: block;
    background: #e69500;
    position: absolute;
    transform: skewX(-20deg);
    left: -10%;
    top: 0;
    z-index: -12;
    transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
    box-shadow: 2px 0px 14px rgba(0, 0, 0, .6);
}

.aboutCourse .first .apply::after {
    content: '';
    width: 0%;
    height: 100%;
    display: block;
    background: #001d6b;
    position: absolute;
    transform: skewX(-20deg);
    left: -10%;
    top: 0;
    z-index: -15;
    transition: all .4s cubic-bezier(.2, .95, .57, .99);
    box-shadow: 2px 0px 14px rgba(0, 0, 0, .6);
}

.aboutCourse .first .apply:hover::before {
    opacity: 1;
    width: 116%;
}

.aboutCourse .first .apply:hover::after {
    opacity: 1;
    width: 120%;
}

.aboutCourse .first span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    color: #686868;
    font-size: 13px;
    margin-top: 15px;
}

.aboutCourse .first span svg {
    color: orange;
}

.aboutCourse .title {
    font-weight: 600;
    font-size: 26px;
}

.aboutCourse .description {
    color: grey;
    font-size: 15px;
}

.aboutCourse .description ul {
    text-decoration: dotted;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: grey;
    list-style: circle;
}

.aboutCourse .description li:first-child {
    margin-top: 10px;
}

.aboutCourse .subTitle {
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #dfdfdf;
}

.aboutCourse .ul {
    text-decoration: dotted;
    padding: 0 20px;
    font-size: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: grey;
    list-style: circle;
}

.aboutCourse .banner {
    background: #e38523;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
}

.aboutCourse .banner .title {
    color: white;
}

.aboutCourse .banner .description {
    color: white;
    font-weight: 500;
}

@keyframes loadingShimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 200% 0;
    }
}