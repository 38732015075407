#principlesContainer {
    display: block;
    overflow: auto;
    box-sizing: border-box;
}

.principlesContainer {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: transparent;
    padding: 0px 15px;
    padding-top: 80px;
    background-size: 200px;
    background-repeat: repeat;
}

.principlesContainer1 {
    height: 100%;
    align-items: center;
    padding: 5px 10px;
    max-width: 1600px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    gap: 30px;
    box-sizing: border-box;
}

.principlesContainer1 > .title {
    font-size: 28px;
    font-weight: 500;
    position: relative;
    padding: 0 15px;
}

.principlesContainer1 > .title::before {
    position: absolute;
    content: "";
    border-top: solid 8px orange;
    border-radius: 100px;
    max-width: 300px;
    width: 100%;
    min-width: 100px;
    top: -10px;
}

.principle {
    background-color: white;
    display: flex;
    flex-direction: row;
    padding: 40px 25px;
    padding-right: 0px;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
    border-radius: 10px;
    gap: 25px;
    margin: 0 10px;
}

.messageContainer {
    background-color: white;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    padding-right: 0px;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
    border-radius: 10px;
    gap: 25px;
    margin: 0 10px;
    margin-top: 15px;
    height: 400px;
}

.messageContainer  .serviceButton {
    transition: 0.3s ease-in-out;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.messageContainer:hover img {
    filter: brightness(0.5);
    transform: scale(1.2);
}

.messageContainer:hover  .serviceButton {
    color: orange;
    background-color: white;
    
}

.messageContainer  .info {
    display: flex;
    flex-direction: column;
    gap: 25px;
    flex: 1;
    padding: 0 0;
    padding-top: 40px;
    justify-content: space-around;
}

.messageContainer  .messageImageContainer {
    flex: 1;
    overflow: hidden;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
    background-repeat: repeat;
    background-size: 50%;
    height: 100%;
}

@keyframes loadingShimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.messageContainer  .messageImageContainer img {
    transition: 0.5s ease-in-out;
}

.messageContainer  .messageImageContainer img {
    max-height: 400px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.messageContainer  .message {
    padding: 0 25px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 8;
    overflow: hidden;
    text-overflow: ellipsis;
    color: grey;
    height: calc(16px * 13 * 1.5);
    display: block;
    white-space: normal;
    word-wrap: break-word;
}

.messageContainer  .title {
    padding: 0 25px;
    font-size: 23px;
    font-weight: 500;
    position: relative;
    color: #5a5a5a;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 20px;
}

.messageContainer  .title::before {
    position: absolute;
    content: "";
    border-top: solid 8px orange;
    border-radius: 100px;
    max-width: 300px;
    width: 80px;
    min-width: 100px;
    top: -10px;
}

.principleInfo {
    display: flex;
    flex-direction: column;
    flex: 1.2;
    justify-content: space-around;
}

.principleInfo > .title {
    font-size: 23px;
    font-weight: 500;
    position: relative;
    color: #5a5a5a;
}

.principleInfo > .title::before {
    position: absolute;
    content: "";
    border-top: solid 8px orange;
    border-radius: 100px;
    max-width: 300px;
    width: 80px;
    min-width: 100px;
    top: -10px;
}

.principleInfo > .title > span {
    font-size: 25px;
    font-weight: 800;
    position: relative;
    color: orange;
}

.principleInfo > .description {
    color: grey;
    font-size: 17px;
    flex: 1;
}

.principleIcons {
    display: grid;
    grid-template-columns: repeat(2, 45%);
    gap: 6%;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
    align-items: stretch;
}

.principleIcon,
.value1 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.principleIcon > svg,
.value1 > svg {
    color: orange;
    width: 30px;
    height: 30px;
}

.principleIcon > .title,
.value1 > .title {
    font-size: 16px;
    font-weight: 500;
}

.principleIcon > .description,
.value1 > .description {
    font-size: 14px;
    color: grey;
}

.principleImageContainer {
    height: 400px;
    overflow: hidden;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    align-self: center;
    flex: 1;
}

.principleImageContainer > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: 0.5s ease-in-out;
}

.principles {
    display: flex;
    flex-direction: row;
    padding-top: 40px;
    max-width: 1100px;
    align-self: center;
    margin: auto;
    gap: 2%;
}

.principleImages {
    width: 150px;
    margin: 0px 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 15px 20px;
    display: none;
}

.principleImages > span {
    width: 3px;
    height: 70px;
    background-color: orange;
    align-self: center;
    border-radius: 100px;
}

.community {
    width: 100%;
    transform: scale(1.5);
}

.principle1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0px;
    background-color: white;
    border-radius: 15px;
    justify-content: stretch;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
    width: 100%;
}

.principle1:hover .principleButton {
    transition: 0.5s ease-in-out;
    background-color: orange;
    color: white;
}

.principle1:hover .principle1Info > .title::before {
    transition: 0.3s ease-in-out;
    width: 100%;
}

.principle1 > .imageContainer {
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-bottom: 10px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    overflow: hidden;
}

.principle1 > .imageContainer > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.principle1Info {
    width: 100%;
    padding: 10px 20px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: space-between;
    flex: 1;
}

.principle1Info > .title {
    font-size: 18px;
    font-weight: 500;
    color: grey;
    position: relative;
    flex: 1;
}

.principle1Info > .title::before {
    position: absolute;
    content: "";
    border-top: solid 8px orange;
    border-radius: 100px;
    width: 80px;
    top: -10px;
}

.principle1Info > .description {
    color: grey;
    flex: 1;
}

.principleButton {
    border: 2px orange solid;
    width: 100px;
    text-align: center;
    border-radius: 5px;
    color: orange;
    font-size: 15px;
    padding: 5px;
    box-sizing: border-box;
}

.values {
    width: 100%;
    display: grid;
    gap: 10px;
    max-width: 1700px;
    grid-template-columns: repeat(5, 1fr);
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    align-self: center;
    grid-template-areas: 
    "first first second third fourth"
    "first first sixth fifth fifth"
    "eighth nineth seventh fifth fifth"; 
}

.values > section {
    border-radius: 10px;
}

.values section:nth-child(1) {
    grid-area: first;
}

.values section:nth-child(2) {
    grid-area: second;
}

.values section:nth-child(3) {
    grid-area: third;
}

.values section:nth-child(4) {
    grid-area: fourth;
}

.values section:nth-child(5) {
    grid-area: fifth;
}

.values section:nth-child(6) {
    grid-area: sixth;
}

.values section:nth-child(7) {
    grid-area: seventh;
}

.values section:nth-child(8) {
    grid-area: eighth;
}

.values section:nth-child(7) {
    grid-area: nineth;
}

.value,
.value1,
.value2 {
    background-color: white;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
}

.value {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.value2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.value1 {
    padding: 20px 15px;
    justify-content: space-between;
}

.value > .imgContainer {
    width: 100%;
    height: 220px;
    background-color: orange;
    object-fit: cover;
    margin-bottom: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.value > .imgContainer > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s ease-in-out;
}

.value2 > .imgContainer {
    flex: 1;
    background-color: orange;
    object-fit: cover;
    margin-bottom: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
}

.value:hover > .imgContainer > img {
    transform: scale(1.05);
    filter: brightness(0.5);
}

.value2 > .imgContainer > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.valueInfo {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    flex: 1;
    justify-content: space-around;
}

.valueInfo > .title {
    font-size: 16px;
    font-weight: 500;
    color: grey;
    position: relative;
}

.valueInfo > .description {
    flex: 1;
    font-size: 14.5px;
}

.valueInfo > .title::before {
    position: absolute;
    content: "";
    border-top: solid 5px orange;
    border-radius: 100px;
    width: 80px;
    top: -10px;
    transition: 0.3s ease-in-out;
}

.value:hover > .valueInfo > .title::before {
    width: 100%;
    
}

.valueButton {
    border: 2px orange solid;
    width: 100px;
    text-align: center;
    border-radius: 5px;
    color: orange;
    font-size: 15px;
    padding: 5px;
    box-sizing: border-box;
    margin-top: 5px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    position: relative;
    overflow: hidden !important;
    z-index: 10;
}
.valueButton:hover {
    color: white;
}

.valueButton::before {
    content: '';
    width: 0%;
    height: 100%;
    display: block;
    background: #e69500;
    position: absolute;
    transform: skewX(-20deg);
    left: -10%;
    opacity: 1;
    top: 0;
    z-index: -12;
    transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
    box-shadow: 2px 0px 14px rgba(0, 0, 0, .6);
}

.valueButton::after {
    content: '';
    width: 0%;
    height: 100%;
    display: block;
    background: #001d6b;
    position: absolute;
    transform: skewX(-20deg);
    left: -10%;
    opacity: 0;
    top: 0;
    z-index: -15;
    transition: all .4s cubic-bezier(.2, .95, .57, .99);
    box-shadow: 2px 0px 14px rgba(0, 0, 0, .6);
}

.valueButton:hover::before {
    opacity: 1;
    width: 116%;
}

.valueButton:hover::after {
    opacity: 1;
    width: 120%;
}

/* Media Queries */
@media screen and (max-width: 700px) {
    .principlesContainer {
        padding-top: 30px;
    }
}

@media screen and (max-width: 440px) {
    .principlesContainer,
    .principlesContainer1 {
        padding-left: 0;
        padding-right: 0;
    }

    .messageContainer > .info {
        padding-top: 0px;
        padding-bottom: 20px;
    }

    .principle1Info > .title {
        font-size: 16px;
    }

    .principle1Info > .description {
        font-size: 14px;
    }

    .valueInfo > .title {
        font-size: 15px;
    }

    .valueInfo > .description {
        font-size: 14px;
    }

    .valueButton {
        width: 100px;
        font-size: 13px;
    }
}

@media screen and (max-width: 900px) {
    .principlesContainer1 > .title {
        font-size: 26px;
    }
}

@media screen and (max-width: 650px) {
    .principlesContainer1 > .title {
        font-size: 26px;
    }
}

@media screen and (max-width: 600px) {
    .principlesContainer1 > .title {
        font-size: 24px;
    }
    .messageContainer > .title {
        font-size: 20px;
    }
}

@media screen and (max-width: 860px) {
    .principle {
        flex-direction: column;
        gap: 40px;
    }

    .messageContainer {
        height: 100%;
        flex-direction: column-reverse;
        gap: 40px;
    }

    .messageContainer .info {
        padding-top: 0px;
        padding-bottom: 20px;
    }

    .principleImageContainer {
        max-height: 300px;
        height: 300px;
    }
}

@media screen and (max-width: 460px) {
    .principle {
        flex: 1;
    }
}

@media screen and (max-width: 1130px) {
    .values {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (max-width: 990px) {
    .values {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: 
        "first first fifth fifth"
        "first first fifth fifth"
        "eighth nineth second third"
        "sixth fourth seventh seventh";
    }
}

@media screen and (max-width: 920px) {
    .values {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: 
        "first first third"
        "first first fourth"
        "second seventh eighth"
        "sixth fifth fifth"
        "nineth fifth fifth";
    }
}

@media screen and (max-width: 680px) {
    .values {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 
        "first first"
        "third fourth"
        "second seventh"
        "eighth sixth"
        "fifth fifth"
        "nineth nineth";
    }
}

@media screen and (max-width: 500px) {
    .values {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: 
        "first"
        "second"
        "third"
        "fourth"
        "sixth"
        "seventh"
        "eighth"
        "nineth"
        "fifth";
    }
}

@media screen and (max-width: 1436px) {
    .value > .imgContainer {
        height: 250px;
    }
}

@media screen and (max-width: 1231px) {
    .value > .imgContainer {
        height: 300px;
    }
}

@media screen and (max-width: 920px) {
    .value > .imgContainer {
        height: 260px;
    }
}

@media screen and (max-width: 737px) {
    .value > .imgContainer {
        height: 290px;
    }
}

@media screen and (max-width: 550px) {
    .value > .imgContainer {
        height: 230px;
    }

    .value2 > .imgContainer {
        height: 200px;
    }
}