.loginContainer {
    display: flex;
    flex-direction: row;
    max-width: 1250px;
    margin: auto;
    width: 100%;
    margin: 0 15px;
    border-radius: 10px;
    overflow: hidden;
    border: 2px solid #eeeeee;
    box-sizing: border-box;
    max-height: 800px;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    @media screen and (width < 570px) {
        flex-direction: column-reverse;
        gap: 50px
    }


.leftSide {
    box-sizing: border-box;

    background-color: green;
    flex: 1;
    position: relative;

    > img {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        filter: brightness(0.2);
    }
    .info {
        position: absolute;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        color: white;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        padding: 30px;

        .logoContainer {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;
            .logoText {
                color: white;
                margin: 0;
            }
        }
        .bottomText {
            font-size: 18px;
            font-weight: 600;
        }
    }
}
.rightSide {
    background: white;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    .info {
        max-width: 350px;
        width: 100%;
        display: flex;
        margin: 0 20px;
        flex-direction: column;
        align-items: flex-start;
        /* background-color: #ffff004d; */
    }
    .title {
        font-weight: 600;
        font-size: 22px;
        text-align: left;
    }
    .description {
        color: grey;
        font-size: 15px;
        margin-bottom: 10px;
    }
    .form {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        width: 100%;
    }
    .label {
        font-weight: 500;
        font-size: 14px;
    }
    .loginInput {
        border: 1px solid #e2e2e2;
        border-radius: 5px;
        height: 35px;
        width: 100%;
        outline: none;
        padding: 5px;
        font-size: 14px;

    }
    .loginButton {
        margin-top: 20px;
        background-color: rgba(235, 153, 1, 0.863);
        width: 100px;
        text-align: center;
        color: white;
        height: 33px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        
    }
    .errors {
        color: #e70000;
        margin-top: 15px;
        font-size: 14px;
    }
    
    
}
}