#newsContainer {
    display: flex;
}

#newsContainer .title {
    font-size: 23px;
    font-weight: 500;
    position: relative;
    color: #5a5a5a;
}

#newsContainer .title::before {
    position: absolute;
    content: "";
    border-top: solid 8px orange;
    border-radius: 100px;
    max-width: 300px;
    width: 80px;
    min-width: 100px;
    top: -10px;
}

.newsContainer {
    width: 100%;
    max-width: 1600px;
    margin: auto;
    padding: 0px 15px;
    /* padding-top: 80px; */
}

.grid  {
    display: grid;
    margin-top: 20px;
    grid-template-columns: repeat(8, 1fr);
    grid-template-areas: 
        'first first first first fifth fifth sixth sixth'
        'third third fourth fourth second second second second';
    gap: 10px; 
    width: 100%;
}


.grid .newsBanners {
    display: flex;
    gap: 20px;
    flex-direction: row;
}

/* Flex item adjustments */
.grid .newsBanners .newsBanner:nth-child(1) {
    flex: 1.5;
}

.grid .newsBanners .newsBanner:nth-child(2) {
    flex: 1;
}
.grid .newsBanner:nth-child(1),
.grid .newsBanner:nth-child(2) {
    position: relative;
    aspect-ratio: 4 / 2; /* Adjusted to match max-height */
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    height: 100%;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239,239,239,1.00) 0%, rgba(221,221,221,1.00) 40%, rgba(239,239,239,1.00) 80%, rgba(239,239,239,1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
    animation: loadingShimmer 1s linear infinite;
    max-height: 400px;
}

.grid .newsBanner:nth-child(1):hover img,
.grid .newsBanner:nth-child(2):hover img {
    filter: brightness(0.9);
    transform: scale(1.1);
}

.grid .newsBanner:nth-child(1) img,
.grid .newsBanner:nth-child(2) img {
    transition: 0.3s ease-in-out;
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: brightness(0.5);
}

.grid .newsBanner:nth-child(1) .infoContainer,
.grid .newsBanner:nth-child(2) .infoContainer {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.grid .newsBanner:nth-child(1) .infoContainer .glance,
.grid .newsBanner:nth-child(2) .infoContainer .glance {
    opacity: 1;
    margin: 10px;
    border: 1px solid orange;
    background-color: orange;
    width: 130px;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 15px;
    padding: 3px 0;
    border-radius: 3px;
}

.grid .newsBanner:nth-child(1) .infoContainer .info,
.newsBanner:nth-child(2) .infoContainer .info {
    margin: 10px;
    color: white;
    display: flex;
    flex-direction: column;
    background-color: transparent;
}

.grid .newsBanner:nth-child(1) .infoContainer .info .postDate,
.grid .newsBanner:nth-child(2) .infoContainer .info .postDate {
    color: white;
    font-weight: 500;
    font-size: 14px;
}

.grid .newsBanner:nth-child(1) .infoContainer .info .postTitle,
.grid .newsBanner:nth-child(2) .infoContainer .info .postTitle {
    font-weight: 600;
    font-size: 15px;
    padding-top: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3; /* Number of lines to display */
    overflow: hidden;
    text-overflow: ellipsis;
}

.grid .newsBanner:nth-child(1) .infoContainer .info .postTitle::before,
.grid .newsBanner:nth-child(2) .infoContainer .info .postTitle::before {
    position: absolute;
    content: "";
    border-top: solid 6px orange;
    border-radius: 5px;
    max-width: 300px;
    width: 100%;
    min-width: 100px;
    top: 10px;
}

/* Keyframes */
@keyframes loadingShimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.grid .newsBanner:nth-child(3),
.grid .newsBanner:nth-child(4),
.grid .newsBanner:nth-child(5),
.grid .newsBanner:nth-child(6) {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    border-radius: 5px;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239,239,239,1.00) 0%, rgba(221,221,221,1.00) 40%, rgba(239,239,239,1.00) 80%, rgba(239,239,239,1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
    animation: loadingShimmer 1s linear infinite;
    max-height: 100%;
}

.grid .newsBanner:nth-child(3):hover img,
.grid .newsBanner:nth-child(4):hover img,
.grid .newsBanner:nth-child(5):hover img,
.grid .newsBanner:nth-child(6):hover img {
    filter: brightness(0.9);
    transform: scale(1.1);
}

.grid .newsBanner:nth-child(3) img,
.grid .newsBanner:nth-child(4) img,
.grid .newsBanner:nth-child(5) img,
.grid .newsBanner:nth-child(6) img {
    transition: 0.3s ease-in-out;
    aspect-ratio: 4 / 2.3;
    object-fit: cover;
    z-index: 1;
    filter: brightness(0.5);
}

@media screen and (max-width: 650px) {
    .grid .newsBanner:nth-child(3) img,
    .grid .newsBanner:nth-child(4) img,
    .grid .newsBanner:nth-child(5) img,
    .grid .newsBanner:nth-child(6) img {
        max-height: 240px;
    }
}

.grid .newsBanner:nth-child(3) .infoContainer,
.grid .newsBanner:nth-child(4) .infoContainer,
.grid .newsBanner:nth-child(5) .infoContainer,
.grid .newsBanner:nth-child(6) .infoContainer {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    z-index: 10;
    height: 100%;
}

.grid .newsBanner:nth-child(3) .infoContainer .glance,
.grid .newsBanner:nth-child(4) .infoContainer .glance,
.grid .newsBanner:nth-child(5) .infoContainer .glance,
.grid .newsBanner:nth-child(6) .infoContainer .glance {
    display: none;
}

.grid .newsBanner:nth-child(3) .infoContainer .info,
.grid .newsBanner:nth-child(4) .infoContainer .info,
.grid .newsBanner:nth-child(5) .infoContainer .info,
.grid .newsBanner:nth-child(6) .infoContainer .info {
    z-index: 10;
    background-color: white;
    height: 100%;
    padding: 15px;
    color: black;
    display: flex;
    flex-direction: column;
    margin: 0;
    border: 1px solid #e4e4e4;
    border-top: none;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.grid .newsBanner:nth-child(3) .infoContainer .info .postDate,
.grid .newsBanner:nth-child(4) .infoContainer .info .postDate,
.grid .newsBanner:nth-child(5) .infoContainer .info .postDate,
.grid .newsBanner:nth-child(6) .infoContainer .info .postDate {
    color: grey;
    font-size: 13px;
    font-weight: 500;
}

.grid .newsBanner:nth-child(3) .infoContainer .info .postTitle,
.grid .newsBanner:nth-child(4) .infoContainer .info .postTitle,
.grid .newsBanner:nth-child(5) .infoContainer .info .postTitle,
.grid .newsBanner:nth-child(6) .infoContainer .info .postTitle {
    font-weight: 600;
    font-size: 15px;
    padding-top: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Number of lines to display */
    overflow: hidden;
    text-overflow: ellipsis;
}

.grid .newsBanner:nth-child(3) .infoContainer .info .postTitle::before,
.grid .newsBanner:nth-child(4) .infoContainer .info .postTitle::before,
.grid .newsBanner:nth-child(5) .infoContainer .info .postTitle::before,
.grid .newsBanner:nth-child(6) .infoContainer .info .postTitle::before {
    position: absolute;
    content: "";
    border-top: solid 6px orange;
    border-radius: 5px;
    max-width: 300px;
    width: 100%;
    min-width: 100px;
    top: 10px;
}

/* Keyframes */
@keyframes loadingShimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 200% 0;
    }
}
.grid .newsBanner:nth-child(1) {
    grid-area: first;
}

.grid .newsBanner:nth-child(2) {
    grid-area: second;
}

.grid .newsBanner:nth-child(3) {
    grid-area: third;
}

.grid .newsBanner:nth-child(4) {
    grid-area: fourth;
}

.grid .newsBanner:nth-child(5) {
    grid-area: fifth;
}

.grid .newsBanner:nth-child(6) {
    grid-area: sixth;
}

/* Media Queries */
@media screen and (max-width: 1195px) {
    .grid .newsBanner:nth-child(2) {
        display: none;
    }
}

@media screen and (max-width: 700px) {
    .grid .newsBanner:nth-child(5),
    .grid .newsBanner:nth-child(6) {
        /* Uncomment if you want to hide these on smaller screens */
        /* display: none; */
    }
}

/* Media Queries */
@media screen and (max-width: 830px) {
    .grid .newsBanner:nth-child(3),
    .grid .newsBanner:nth-child(4),
    .grid .newsBanner:nth-child(5),
    .grid .newsBanner:nth-child(6) {
        max-height: 100%;
    }
}

@media screen and (max-width: 700px) {
    .grid .newsBanner:nth-child(3) .infoContainer .info .postTitle,
    .grid .newsBanner:nth-child(4) .infoContainer .info .postTitle,
    .grid .newsBanner:nth-child(5) .infoContainer .info .postTitle,
    .grid .newsBanner:nth-child(6) .infoContainer .info .postTitle {
        font-size: 14px;
    }
}

/* Media Queries */
@media screen and (max-width: 700px) {
    .grid .newsBanner:nth-child(1) .infoContainer .info .postTitle,
    .grid .newsBanner:nth-child(2) .infoContainer .info .postTitle {
        font-size: 14px;
    }
}

/* Media Queries */
@media screen and (max-width: 948px) {
    .grid .newsBanners {
        flex-direction: column;
    }

    .grid .newsBanners .newsBanner {
        max-height: 320px;
        min-height: 250px;
    }
}

/* Media Queries */
@media screen and (max-width: 1195px) {
    .grid {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas: 
            'first first sixth'
            'third fourth fifth';
    }
}

@media screen and (max-width: 830px) {
    .grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas: 
            'first first'
            'third fourth'
            'fifth sixth';
    }
}

@media screen and (max-width: 650px) {
    .grid {
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: 
            'first first'
            'third third'
            'fourth fourth'
            'fifth fifth'
            'sixth sixth';
    }
}

@media screen and (max-width: 948px) {
    .grid .newsBanner {
        max-height: 320px;
        min-height: 250px;
    }
}


/* Media Queries */
@media screen and (max-width: 900px) {
    #newsContainer .title {
        font-size: 20px;
    }
}

@media screen and (max-width: 650px) {
    #newsContainer .title {
        font-size: 20px;
    }
}

@media screen and (max-width: 600px) {
    #newsContainer .title {
        font-size: 18px;
    }
}

@media screen and (max-width: 700px) {
    /* Add styles for newsContainer if needed */
}

@media screen and (max-width: 440px) {
    .newsContainer {
        padding-left: 10px;
        padding-right: 10px;
    }
}
