.footerCont {
    background-color: #023047;
    padding: 20px;
    margin-top: 100px;
    padding-top: 50px;
    padding-bottom: 0px;
    z-index: 10;
}

.footerCont1 {
    max-width: 1900px;
    margin: auto;
    display: flex;
    flex-direction: row;
    padding: 20px 0px;
    gap: 20px;
}

.footerLeft {
    flex: 1;
}

.footerLeft > div {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin-bottom: 50px;
}

.footerLeft > div > span {
    color: white;
    font-weight: 600;
    font-size: 17px;
    text-transform: uppercase;
    max-width: 500px;
    flex: 1.5;
}

.footerLeft > div > .imgCont {
    flex: 1;
    max-width: 130px;
    max-height: 130px;
}

.footerLeft > div > .imgCont > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.footerBottom {
    flex: 1.5;
}

.footerGrid {
    color: white;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.footerGrid > .column {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footerGrid .item {
    padding-right: 5px;
    font-size: 14px;
}

.footerGrid > .column > .title {
    font-weight: 800;
    font-size: 14px;
}

.footerGrid .item:hover {
    color: #ffa500;
}

.footerGrid1 {
    display: grid;
    margin-top: 50px;
    color: white;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: 
    "first first first second";
}

.footerGrid1 > div:nth-child(1) {
    grid-area: first;
    padding-right: 50px;
    font-size: 14.5px;
}

.footerGrid1 > div:nth-child(2) {
    grid-area: second;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.footerGrid1 > div:nth-child(2) > svg {
    width: 27px;
    height: 27px;
    cursor: pointer;
}

.madeBy {
    padding: 10px;
    background-color: #003a57;
    margin: 0 -20px;
    text-align: center;
    color: white;
    margin-top: 30px;
    font-size: 14px;
}

/* Media Queries */
@media screen and (max-width: 500px) {
    .footerCont {
        margin-top: 60px;
    }
}

@media screen and (max-width: 810px) {
    .footerLeft > div {
        margin: 0 -40px;
    }
    .footerGrid {
        margin: 0 -25px;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
    }
    .footerGrid1 {
        margin-left: -25px;
        margin-right: -25px;
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas: 
        "first"
        "second";
        padding-right: 0;
    }
}

@media screen and (max-width: 405px) {
    .footerGrid {
        grid-template-columns: repeat(1, 1fr);
        gap: 50px;
    }
}

@media screen and (max-width: 1330px) {
    .footerCont1 {
        flex-direction: column;
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media screen and (max-width: 1490px) {
    .footerLeft > div > span {
        max-width: 300px;
    }
}

@media screen and (max-width: 600px) {
    .footerLeft > div > span {
        font-size: 15px;
    }
    .footerLeft > div > .imgCont {
        max-width: 110px;
        max-height: 110px;
    }
}
