.card {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 20px;
    box-shadow: 0 0 10px 10px rgba(184, 184, 184, 0.171);
    width: 500px;
    margin: 15px 20px;
    height: 220px;
    justify-content: center;
    flex: 1;
}

.cardTop {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

svg[class~='bi-facebook'] {
    color: #0165E1;
    width: 25px;
    height: 25px;
}

svg[class~='bi-instagram'] {
    color: #E1306C;
    width: 23px;
    height: 23px;
}

.cardName {
    font-family: Arial, Helvetica, sans-serif;
    color: #727272;
    font-size: 14.5px;
    font-weight: 600;
    margin: 13px 0;
}

.cardData {
    font-family: Arial, Helvetica, sans-serif;
    color: #868686;
    font-size: 14.5px;
}

.cardBottom {
    font-family: Arial, Helvetica, sans-serif;
    color: #8a8a8a;
    font-size: 14.5px;
}

svg[class~='bi-star-fill'] {
    color: #FFB703;
    width: 20px;
    height: 20px;
}

.cardStars {
    display: flex;
    flex-direction: row;
}

/* Media Queries */
@media screen and (max-width: 700px) {
    .card {
        width: 70vw;
        margin: 15px;
    }
}

@media screen and (max-width: 400px) {
    .cardName,
    .cardData,
    .cardBottom {
        font-size: 12.5px;
    }
}
