 
 .historyContainer {
    padding: 50px 0;
    margin: 20px auto;
    position: relative;
    overflow: hidden;
}
 
 .historyContainer:before {
    content: '';
    position: absolute;
    top: 0;
    left: 49.9%;
    margin-left: -1px;
    width: 4px;
    height: 100%;
    background: #ffbb3c;
    z-index: 1
 }
 
 .timeline-block {
    width: -webkit-calc(50% + 9px);
    width: -moz-calc(50% + 9px);
    width: calc(50% + 9px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -moz-box-pack: justify;
    justify-content: space-between;
    clear: both;
    
 }
 
 .timeline-block-right {
    float: right;
 }
 
 .timeline-block-left {
    float: left;
    direction: rtl;

    .timeline-content {
        text-align: center;
        direction: ltr;
    }
 }
 
 .marker {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 4px solid white;
    background: orange;
    margin-top: 10px;
    z-index: 100
 }
 
 .timeline-content {
    width: 95%;
    padding: 20px;
    color: #666;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
    margin: 0 5px;
    text-align: center;
 }
 
 .timeline-content h3 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 500
 }
 
 .timeline-content span {
    font-size: 15px;
    color: #f59f00;
    font-weight: 500;
 }
 
 .timeline-content p {
    font-size: 14px;
    line-height: 1.5em;
    word-spacing: 1px;
    color: #888;
 }
 
 
 @media screen and (max-width: 768px) {
    .historyContainer:before {
       left: 9.5px;
       width: 3px;
    }
    .timeline-block {
       width: 100%;
       margin-bottom: 30px;
    }
    .timeline-block-right {
       float: none;
       .timeline-content {
        text-align: left;
        direction: ltr;
    }
    }
 
    .timeline-block-left {
       float: none;
       direction: ltr;

       .timeline-content {
        text-align: left;
        direction: ltr;
    }
    }
 }
 