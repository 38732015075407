.eventsContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.eventsContainer .eventContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    aspect-ratio: 4/5.5;
    border-radius: 25px;
    overflow: hidden;
    max-width: 2000px;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91,91,91,1.00) 0%, rgba(112,112,112,1.00) 40%, rgba(91,91,91,1.00) 80%, rgba(91,91,91,1.00) 97%);
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239,239,239,1.00) 0%, rgba(221,221,221,1.00) 40%, rgba(239,239,239,1.00) 80%, rgba(239,239,239,1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
}

@keyframes loadingShimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 200% 0;
    }
}

.eventsContainer .eventContainer > img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
    filter: brightness(0.5);
    transition: 1s ease-in-out;
}

.eventsContainer .eventContainer:hover > img {
    transform: scale(1.4);
    filter: brightness(0.8);
}

.eventsContainer .eventInfo {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: white;
    bottom: 15px;
    left: 15px;
    right: 15px;
    padding: 20px;
    border-radius: 20px;
    height: 255px;
    justify-content: space-between;
}

.eventsContainer .eventTitle {
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 15px;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.eventsContainer .eventTitle:hover {
    text-decoration: underline;
    cursor: pointer;
}

.eventsContainer .eventDate {
    color: grey;
    font-size: 15px;
    background-color: #dfdfdfa8;
    padding: 7px;
    border-radius: 20px;
    width: 130px;
    align-items: center;
    text-align: center;
    margin-top: 5px;
}

.eventsContainer .eventLocation {
    display: flex;
    margin-top: 5px;
    gap: 15px;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #d3d3d3;
    border-radius: 50px;
    border-style: dashed;
    font-size: 14px;
}

.eventsContainer .iconContainer {
    background-color: #E3E6DD;
    height: 40px;
    width: 40px;
    border-radius: 500px;
    display: flex;
    justify-content: center;
}

.eventsContainer .iconContainer img {
    width: 30px;
    align-self: center;
    height: 30px;
}

.eventsContainer .eventLocationInfo {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
}

.eventsContainer .eventButton {
    border: 2px orange solid;
    width: 100px;
    text-align: center;
    border-radius: 50px;
    color: orange;
    font-size: 14px;
    padding: 3px 5px;
    box-sizing: border-box;
    margin-top: 5px;
    cursor: pointer;
    transition: 0.5s ease-in-out;
    background: transparent!important;
    z-index:10;
    position: relative;
    overflow: hidden!important;
    transition: all .3s ease-in-out;
}

.eventsContainer .eventButton:hover {
    color: white;
}

.seeMore {
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 5px 5px;
    font-size: 16px;
    font-weight: 600;
    color: #e69500;
    border-radius: 7px;
    width: 100%;
    justify-content: center;
    border: 2px solid #e69500;
    max-width: 250px;
    margin: auto;
    margin-top: 40px;
    cursor: pointer;
    width: 100%;
    transition: 0.3s ease-in-out;
    /*  */
    
    position: relative;
    overflow: hidden!important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background: white;
    z-index:10;

    
}

.seeMore:hover {
    border: 2px solid #e69500;
    color: #ffffff!important;
}

.seeMore::before,
.eventsContainer .eventButton::before {
    content: '';
    width: 0%;
    height: 100%;
    background: #e69500;
    position: absolute;
    transform: skewX(-20deg);
    left: -10%;
    opacity: 1;
    top: 0;
    z-index: -12;
    transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
    box-shadow: 2px 0px 14px rgba(0,0,0,.6);
}

.seeMore::after,
 .eventButton::after {
    content: '';
    width: 0%;
    height: 100%;
    background: #001d6b;
    position: absolute;
    transform: skewX(-20deg);
    left: -10%;
    opacity: 0;
    top: 0;
    z-index: -15;
    transition: all .4s cubic-bezier(.2,.95,.57,.99);
    box-shadow: 2px 0px 14px rgba(0,0,0,.6);
}

.seeMore:hover::before,
.eventButton:hover::before {
    width: 116%;
}

.seeMore:hover::after,
.eventButton:hover::after {
    opacity: 1;
    width: 120%;
}

/* Media Queries */
@media screen and (max-width: 1420px) {
    .eventsContainer {
        grid-template-columns: repeat(2, 1fr);
    }

    .eventsContainer .eventContainer {
        aspect-ratio: 3/3;
    }
}

@media screen and (max-width: 1020px) {
    .eventsContainer .eventContainer {
        aspect-ratio: 3/3;
    }
}

@media screen and (max-width: 900px) {
    .eventsContainer .eventContainer {
        aspect-ratio: 4/5;
    }
}

@media screen and (max-width: 700px) {
    .eventsContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .eventsContainer .eventContainer {
        aspect-ratio: unset;
        height: calc(300px + 20vh);
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media screen and (max-width: 440px) {
    .eventsContainer .eventButton {
        width: 80px;
        font-size: 13px;
    }

    .seeMore {
        width: 50%;
    }
}
