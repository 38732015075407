.soonContainer {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.soonContainer .soonInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.soonContainer .soonInfo .title {
    text-align: center;
    color: white;
    font-size: 35px;
    font-weight: 800;
    margin-bottom: 20px;
}

.soonContainer .soonInfo .description {
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 20px;
}

.soonContainer .soonInfo .socials {
    display: flex;
    flex-direction: row;
    align-self: center;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.soonContainer .soonInfo .socials svg {
    width: 30px;
    height: 30px;
    color: white;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}