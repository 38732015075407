.regulations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
}

@media screen and (max-width: 670px) {
    .regulations {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 513px) {
    .regulations {
        grid-template-columns: repeat(1, 1fr);
    }
}

.regContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
    padding: 20px;
    gap: 20px;
    margin-top: 25px;
    justify-content: space-between;
}

.regContainer .imgContainer {
    background: white;
    height: 80px;
    width: 80px;
    margin: auto;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -60px;
}

.regContainer .imgContainer img {
    object-fit: contain;
    width: 100%;
    height: 80px;
}

.regContainer .title {
    font-weight: 700;
    text-align: center;
    font-size: 14px;
}

.regContainer .description {
    color: #949494;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin: -10px 0;
}

.regContainer .regButton {
    background: #004f77;
    color: white;
    width: 100px;
    text-align: center;
    padding: 10px 0;
    align-self: center;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    transition: 0.2s ease-in-out;
}

.regContainer .regButton:hover {
    background: #004f77b0;
}
