.servicesContainer {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    background-color: transparent;
    padding: 0px 15px;
    background-size: 200px;
    background-repeat: repeat;
}

.servicesContainer1 {
    height: 100%;
    align-items: center;
    padding: 5px 10px;
    max-width: 1600px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
    gap: 30px;
    box-sizing: border-box;
    padding: 5px 20px;
}

.servicesHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.servicesControl {
    display: none;
}

.servicesControl > svg {
    width: 35px;
    height: 35px;
    display: none;
    color: orange;
    margin-left: 10px;
}

.servicesHeader > .title {
    font-size: 28px;
    font-weight: 500;
    position: relative;
}

.servicesHeader > .title::before {
    position: absolute;
    content: "";
    border-top: solid 8px orange;
    border-radius: 100px;
    max-width: 300px;
    width: 100%;
    min-width: 100px;
    top: -10px;
}

.services {
    display: grid;
    grid-template-columns: repeat(3, 32.5%);
    gap: 1%;
    padding-top: 20px;
    align-items: center;
    justify-content: center;
    align-items: stretch;
    position: relative;
    height: 100%;
    overflow-y: hidden;
    padding-bottom: 15px;
}

.serviceImage {
    width: 100%;
    height: 300px;
    object-fit: cover;
    transition: 0.5s ease-in-out;
    z-index: 1;
    border-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.service {
    background-color: transparent;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    margin-bottom: 0px;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
    overflow: hidden;
    background-color: #5c5c5c;
    background-image: linear-gradient(90deg, rgba(91, 91, 91, 1.00) 0%, rgba(112, 112, 112, 1.00) 40%, rgba(91, 91, 91, 1.00) 80%, rgba(91, 91, 91, 1.00) 97%);
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loadingShimmer;
    animation-timing-function: linear;
    background-color: #efefef;
    background-image: linear-gradient(90deg, rgba(239, 239, 239, 1.00) 0%, rgba(221, 221, 221, 1.00) 40%, rgba(239, 239, 239, 1.00) 80%, rgba(239, 239, 239, 1.00) 97%);
    background-repeat: no-repeat;
    background-size: 50%;
    border-radius: 10px;
    overflow: hidden;
}

.service:hover .serviceTitle::before {
    transition: 0.3s ease-in-out;
    width: 100%;
}

.service:hover .serviceImage {
    filter: brightness(0.5);
    transform: scale(1.2);
}

.serviceButton::before {
    content: '';
    width: 0%;
    height: 100%;
    display: block;
    background: #e69500;
    position: absolute;
    transform: skewX(-20deg);
    left: -10%;
    opacity: 1;
    top: 0;
    z-index: -12;
    transition: all .7s cubic-bezier(0.77, 0, 0.175, 1);
    box-shadow: 2px 0px 14px rgba(0, 0, 0, .6);
}

.serviceButton::after {
    content: '';
    width: 0%;
    height: 100%;
    display: block;
    background: #001d6b;
    position: absolute;
    transform: skewX(-20deg);
    left: -10%;
    opacity: 0;
    top: 0;
    z-index: -15;
    transition: all .4s cubic-bezier(.2, .95, .57, .99);
    box-shadow: 2px 0px 14px rgba(0, 0, 0, .6);
}

.service:hover,
.messageContainer:hover {
    .serviceButton {
        border: 2px solid #e69500;
        color: #ffffff!important;
    }

    .serviceButton::before,
    .seeMore::before {
        opacity: 1;
        width: 116%;
    }

    .serviceButton::after,
    .seeMore::after {
        opacity: 1;
        width: 120%;
    }
}

.serviceInfo {
    width: 100%;
    padding: 10px 20px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    justify-content: space-between;
    flex: 1;
    z-index: 2;
}

.serviceTitle {
    font-size: 16px;
    font-weight: 500;
    color: grey;
    position: relative;
    flex: 1;
}

.serviceTitle::before {
    position: absolute;
    content: "";
    border-top: solid 8px orange;
    border-radius: 100px;
    width: 80px;
    top: -10px;
}

.serviceDescription {
    color: grey;
    flex: 1;
    font-size: 14.5px;
}

.serviceButton {
    border: 2px orange solid;
    width: 100px;
    text-align: center;
    border-radius: 5px;
    color: orange;
    font-size: 15px;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    overflow: hidden!important;
    transition: all .3s ease-in-out;
    background: transparent!important;
    z-index: 10;
}

/* Media Queries */
@media screen and (max-width: 700px) {
    .servicesContainer {
        margin-top: 50px;
    }
}

@media screen and (max-width: 400px) {
    .servicesContainer {
        
    }
}

@media screen and (max-width: 440px) {
    .servicesContainer1 {
        padding-left: 0;
        padding-right: 0;
    }

    .services {
        
        gap: 5px;
        grid-template-columns: repeat(1, 100%);
    }

    .serviceImage {
        flex: 1;
        width: 100%;
        height: 200px;
    }

    .service {
        width: 100%;
        border-radius: 10px;
    }

    .serviceTitle {
        font-size: 15px;
    }

    .serviceDescription {
        font-size: 14px;
    }

    .serviceButton {
        width: 100px;
        font-size: 13px;
    }
}

@media screen and (max-width: 1130px) {
    .servicesControl {
        display: flex;
    }
    .servicesControl > svg {
        display: flex;
    }

    .services {
        overflow-x: scroll;
        width: 100%;
        justify-content: flex-start;
        grid-template-columns: repeat(3, 350px);
        gap: 3%;
    }

    .service {
        width: 100%;
        margin-right: 200px;
    }
}

@media screen and (max-width: 600px) {
    .servicesControl {
        display: none;
    }

    .services {
        align-items: center;
        justify-content: center;
        gap: 15px;
        grid-template-columns: repeat(1, 100%);
        overflow-x: hidden;
    }

    .serviceImage {
        filter: brightness(0.5);
        transform: scale(1.2);
    }
}

@media screen and (max-width: 450px) {
    .service {
        margin-bottom: 30px;
        min-width: 270px;
    }
}

@media screen and (max-width: 300px) {
    .service {
        margin-bottom: 30px;
        min-width: 250px;
    }
}
