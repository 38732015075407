.adminGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    margin-top: 20px;
    justify-content: stretch;
}

/* Grid Item */
.adminGrid .gridItem {
    background-color: white;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.adminGrid .title {
    font-size: 14.5px;
    font-weight: 500;
    color: grey;
    margin-bottom: 20px;
}

.adminGrid .gridValue {
    font-size: 35px;
    font-weight: 500;
    margin-top: -15px;
    align-self: center;
}

/* Subordinates */
.adminGrid .subordinates {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.308);
    padding: 20px;
    gap: 15px;
    margin-top: 25px;
}

.adminGrid .subordinates div > .imgContainer {
    background: #e7eff3;
    height: 80px;
    width: 80px;
    margin: auto;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #023047;
    font-weight: 700;
    margin-top: -60px;
}

.adminGrid .subordinates .title {
    font-weight: 500;
    text-align: center;
}

.adminGrid .subordinates .description {
    color: grey;
}

/* Media Queries */
@media screen and (max-width: 550px) {
    .adminGrid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 440px) {
    .adminGrid {
        grid-template-columns: repeat(1, 1fr);
    }
}
